import React, { FC, useEffect, useRef, useState } from 'react';
import './App.css';

import { User, Course } from "./interfaces"

import config from './config';
import { ThreeDots } from 'react-loader-spinner';


interface Message {
  from: string,
  msg: string | any
}


interface ChatBoxProps {
  user: User,
  course: Course
}


const ChatBox: FC<ChatBoxProps> = ({ user, course: course }) => {

  const withDebug = config.IS_DEBUG === "TRUE";
  console.log(withDebug)
  const marviBGColour = "rgba(236,220,194, 0.5)";


  const inputRef = useRef<null | HTMLInputElement>(null);

  const [message, setMessage] = useState('');
  const [isAwaitingResponse, setIsAwaitingResponse] = useState<boolean>(false);
  const [debugInfo, setDebugInfo] = useState<any>(false)
  const [profile, setProfile] = useState<string>("standard")

  console.log("course", course)

  const [messages, setMessages] = useState<Array<Message>>([])
  /*const [messages, setMessages] = useState<Array<Message>>([
  {
    from: "ed",
    msg: "Here is my answer to your question. Please enjoy. I'm going to make this a little bit longer. And now a lot longer. It would be good to fix the overflow a little bit"
  },
  {
    from: "student",
    msg: "Hi Marvi, can you explain CPM to me?"
  },
  {
    from: "ed",
    msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam porta sem est, a condimentum ex dictum vitae. Vivamus interdum, dolor et vulputate aliquam, diam magna mollis ante, a placerat metus sem ac dolor.\nNam ligula turpis, feugiat et ipsum at, imperdiet congue ante. Aliquam at dignissim neque, sit amet bibendum quam. Suspendisse ut justo nec lacus eleifend suscipit. Vivamus quis malesuada nunc. Phasellus cursus tristique enim quis sagittis. Donec convallis a est ac euismod. Vestibulum eget erat mauris. Nulla sagittis vestibulum ullamcorper. Duis sed tortor finibus, efficitur metus ut, lobortis ante. Vivamus aliquam sed nunc ac tincidunt.\nDuis sit amet enim tempor, auctor risus ut, efficitur felis. Nunc posuere tincidunt est in condimentum."

  }])*/
  const WELCOME_MSG = "welcome message";

  const scrollToBottom = () => {
    const {current} = inputRef
    if (current !== null)
      current.scrollIntoView()
  }

  const sendMessage = async (message: string) => {

    if (message == "")
      return;

    setIsAwaitingResponse(true);

    if (message !== WELCOME_MSG) {
      setMessages(messages => [...messages, { from: "student", msg: message }]);
    }

    // setMessages(messages => [...messages, { from: "ed", msg: "bypass response" }]);

    fetch(config.BACKEND_URL + "/chat/s", {
      //fetch(process.env.REACT_APP_SERVER_URL!, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        message: message,
        profile: profile,
        remote_user_id: user.remote_id
        // history: messages
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setMessages(messages => [...messages, { from: "ed", msg: data.response }]);
        setIsAwaitingResponse(false)

        if (withDebug && data.debug) {
          console.log(data.debug)
          setDebugInfo(data.debug)
        }

      })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const m = message;
    setMessage("");
    sendMessage(m)

    return;
  }

  useEffect(() => {
    console.log("firing")
    sendMessage(WELCOME_MSG);
  }, [])

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
    inputRef.current?.focus()
  }, [messages])

  return (
    <div className="row justify-content-center">

      <div className="col-lg-6">
        <div className="d-flex justify-content-center mb-3">
          <img
            src="marvi2.png"
            alt=""
            style={{ width: "40%", transform: "scaleX(-1)" }}
          />
        </div>
        {messages.map((message: Message) => (
          (message.from == "student") ?

            <div
              className="p-3 mb-1 rounded-3 bg-secondarys"
            >
              <p className="small fw-bold pt-2 mb-2">You</p>
              <p>{message.msg}</p>
            </div>
            :
            <div
              className='amb-3 rounded-3 px-3 py-3'
              style={{ backgroundColor: marviBGColour }}
            >
              <p className="small fw-bold pt-0 mb-2">Marvi</p>
              {message.msg.split("\n").map((msg: string) => (
                <p className=''>{msg}</p>
              ))}

            </div>
        ))}


        {isAwaitingResponse && (
          <div
            className='p-3 mb-1 rounded-3'
            style={{ backgroundColor: marviBGColour }}
          >
            <div className="d-flex justify-content-center">
              <div style={{ width: "40px" }}>
                <ThreeDots color="#666666" radius="100" height="20" width="40" />
              </div>
            </div>

          </div>
        )}


        <form onSubmit={handleSubmit} className='w-100 ms-2' autoComplete='off'>

          <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3">
            <input autoComplete="false" name="hidden" type="text" style={{ display: "none" }} />

            <img src={user.picture}
              alt=""
              style={{ width: "40px", height: "100%" }}
              referrerPolicy="no-referrer"
              className="rounded-circle"
            />



            <div id="input-box" className="input-group ms-2">
              <input id="enterPrompt" type="text"
                ref={inputRef}
                className="form-control form-control-lg"
                placeholder="Chat with Marvi"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                autoComplete="false"
                disabled={isAwaitingResponse}
                autoFocus
              />
              <button
                className="btn btn-light"
                type="submit"
                id="button-addon2"
              >&raquo;</button>
            </div>
          </div>
        </form>


      </div>
      </div>

  );
}

export default ChatBox;
