import React, { FC, useEffect, useState } from 'react';
import './App.css';
import ChatBox from './ChatBox';

import { User, Course } from './interfaces';
import axios from 'axios';

import config from './config';

interface HomePageStudentProps {
  user: User
}

const HomePageStudent: FC<HomePageStudentProps> = ({user}) => {

  const [courses, setCourses] = useState<Course[] | null>(null);
  const [course, setCourse] = useState<Course | null>(null);
  
  // load up the user's courses onload
  useEffect(() => {
    console.log("local", localStorage);
    console.log("local", JSON.stringify([localStorage]));
    console.log("rem_id", localStorage.getItem(config.LOCALSTORAGE_REMOTE_USER_ID))
    axios
      .get<Course[]>(config.BACKEND_URL + `/user/courses`, {
        params: {remote_user_id: localStorage.getItem(config.LOCALSTORAGE_REMOTE_USER_ID)}
      })
      .then(res => {
        setCourses(res.data)
        setCourse(res.data[0])
        console.log("asdf", courses)
      })
      .catch((err) => console.log(err));
  }, [])

  return (
    <div className="row">
      {/*
      <div className="col-3">

        <div className="card">
          <img src={user.picture} className="card-img-top" alt="..." referrerPolicy="no-referrer" />
          <div className="card-body">
            <h5 className="card-title">{user.first_name} {user.last_name}</h5>
            <p className="card-text">{user.email}
            <br />
            {user.is_teacher ? "Teacher Account" : "Student Account"}

            </p>

          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">Your Courses:</li>
            {courses == null ?
            <li className="list-group-item">Loading...</li>
            :
            (courses.map(c => {
              return (
                <li
                  className={"list-group-item " + (course!.id == c.id && "fw-bold")}
                  onClick={() => setCourse(c)}
                >
                  {c.name}
                </li>
              )
            }))
          }
          </ul>
          <div className="card-body">
            <a href="#" className="card-link">Settings</a>
            <a href="#" className="card-link">Logout</a>
          </div>
        </div>
      </div>
        */}
      {course && (
      <div className="col">
        <ChatBox user={user} course={course}></ChatBox>
      </div>
      )}
    </div>
  );
}

export default HomePageStudent;
