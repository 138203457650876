import React, { FC, useEffect, useState } from 'react';
import './App.css';

import { User, Course, CourseMaterial } from './interfaces';
import axios from 'axios';

import config from './config'


interface HomePageTeacherProps {
  user: User
}



const HomePageTeacher: FC<HomePageTeacherProps> = ({ user }) => {

  const [courses, setCourses] = useState<Course[] | null>(null);
  const [courseId, setCourseId] = useState<number | null>(null);
  const [courseUsers, setCourseUsers] = useState<User[] | null>(null);
  const [courseMaterials, setCourseMaterials] = useState<CourseMaterial[] | null>(null);

  const [studentId, setStudentId] = useState<number | null>(null);
  const [studentInfo, setStudentInfo] = useState<string>("Loading student summary")

  const [selectedStudent, setSelectedStudent] = useState<User | null>(null);

  const [isShowingDocuments, setIsShowingDocuments] = useState<boolean>(false);

  const showDocuments = () => {

    setStudentId(null);
    setIsShowingDocuments(true);

    axios
      .get<CourseMaterial[]>(config.BACKEND_URL + `/courses/${courseId}/materials`)
      .then(res => {
        setCourseMaterials(res.data)
      })
      .catch((err) => console.log(err))
    return;
  }

  // load up the user's courses onload
  useEffect(() => {
    axios
      .get<Course[]>(config.BACKEND_URL + `/user/courses`, {
        params: {remote_user_id: localStorage.getItem(config.LOCALSTORAGE_REMOTE_USER_ID)}
      })
      .then(res => {
        setCourses(res.data)
      })
      .catch((err) => console.log(err));
  }, [])

  // get the course's students
  useEffect(() => {
    setCourseUsers(null);
    setCourseMaterials(null);
    setStudentId(null);

    if (courseId == null)
      return;

    axios
      .get<User[]>(config.BACKEND_URL + `/courses/${courseId}/users`)
      .then(res => {
        setCourseUsers(res.data)
      })
      .catch((err) => console.log(err));

  }, [courseId])

  // load up a student profile
  useEffect(() => {

    if (!studentId) {
      setSelectedStudent(null)
      return;
    }

    setIsShowingDocuments(false);

    courseUsers!.forEach(user => {
      if (user.id === studentId)
        setSelectedStudent(user);
    })

    setStudentInfo("")

    axios
      .get<string>(config.BACKEND_URL + `/chat/student_info/${courseId}/${studentId}`, {
        params: {remote_user_id: localStorage.getItem(config.LOCALSTORAGE_REMOTE_USER_ID)}
      })
      .then(res => {
        setStudentInfo(res.data)
      })
      .catch((err) => console.log(err))

    console.log("studentId changed: ", studentId)
  }, [studentId])

  return (
    <div className="row">
      {/*
      <div className="col-3">
        <div className="card">
          <img src={user.picture ?? "public/no-avatar.gif"} className="card-img-top" alt="..." referrerPolicy="no-referrer" />
          <div className="card-body">
            <h5 className="card-title">{user.first_name} {user.last_name}</h5>
            <p className="card-text">{user.email}
              <br />
              {user.is_teacher ? "Teacher Account" : "Student Account"}

            </p>

          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">Your Courses:</li>
            {courses == null ?
              <li className="list-group-item">Loading...</li>
              :
              (courses.map(course => {
                return (
                  <li
                    className={"list-group-item " + (courseId == course.id && "fw-bold")}
                    onClick={() => setCourseId(course.id)}
                  >
                    {course.name}
                  </li>
                )
              }))
            }
          </ul>
          <div className="card-body">
            <a href="#" className="card-link">Settings</a>
            <a href="#" className="card-link">Logout</a>
          </div>
        </div>
      </div>
      */}


      {courses && (
        <div className="col-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Select a Course:</h5>
              <p className="card-text">
                <select
                  className='form-control'
                  onChange={e => setCourseId(parseInt(e.target.value))}
                  value={courseId ?? 0}
                >
                  <option value="0"></option>
                  {courses.map(course => {
                    return (
                      <option value={course.id}>{course.name}</option>
                    )
                  })}
                </select>
              </p>
            </div>
            {/*(courseId) && (
              <ul className="list-group list-group-flush">
                <li
                  className={"list-group-item " + (isShowingDocuments && "list-group-item-secondary")}
                  role="button"
                  onClick={showDocuments}
                >
                  View course documents
                </li>
              </ul>
            )*/}
          </div>

          {courseUsers && (
            <div className="card mt-3">

              <div className="card-body">
                <p className="card-text">Students</p>
              </div>

              <ul className="list-group list-group-flush" style={{ overflow: "auto", height: "600px" }}>
                {courseUsers == null ?
                  <p className="small">Select a course</p>
                  :

                  (courseUsers.map(user => {
                    if (!user.is_teacher)
                      return (
                        <li
                          className={"list-group-item " + (studentId == user.id && "list-group-item-secondary")}
                          role="button"
                          onClick={() => setStudentId(user.id)}
                        >
                          <img
                            src={user.picture ? user.picture : "no-avatar.gif"}
                            alt=""
                            referrerPolicy="no-referrer"
                            className='rounded-circle me-2'
                            width={"20px"}
                          />
                          {user.first_name} {user.last_name}
                          {user.is_teacher && " - teacher"}
                        </li>
                      )

                  }))

                }
              </ul>

            </div>
          )}


        </div>
      )}

      {isShowingDocuments && (

        <div className="col">
          <h2>Course Documents</h2>

          <p>These documents have been analysed and their contents are used when Marvi answers student questions</p>

          <p>Add a new document</p>



          <p>Course Documents:</p>
          {courseMaterials == null ?
            <p>Select a course</p>
            :
            (courseMaterials.map(material => {
              return (
                <li><a href={material.remote_link} target="_blank">{material.title}</a></li>
              )
            }))
          }

        </div>
      )}

      {selectedStudent && (

        <div className="col">
          <h2>{selectedStudent.first_name} {selectedStudent.last_name}</h2>
          <div>

              {studentInfo.split("\n\n").map((i: string) => (
                <p>{i}</p>
              ))}
              

          </div>
        </div>
      )}
    </div>

  );
}

export default HomePageTeacher;
