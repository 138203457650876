import React, { useState } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

import HomePageStudent from './HomePageStudent';
import HomePageTeacher from './HomePageTeacher';

import { User, GoogleUser } from './interfaces';

import config from './config'
/*
Going to keep SSO quite simple for now.
When they login, we save their profile data to localstorage
When they logout that gets removed

We send their user ID through with each request so the backend can look them up
If the backend can't find them, we show some kind of error


So, user lands:
- check if they have the user profile in localstorage.
   - if yes, let them in
   - if no, redirect straight to the login page
*/


/*
const u: User = {
    id: 1,
    remote_id: "asdf",
    first_name: "asdf",
    last_name: "asdf",
    is_teacher: false,
    email: "asdf",
    picture: "fdsa"
}*/

function App() {

    //document.documentElement.dataset.bsTheme = "dark";
    document.documentElement.dataset.bsTheme = "light";

    // see if we have a user saved in localstorage
    const profileString = localStorage.getItem(config.LOCALSTORAGE_USER)
    const savedUser = profileString ? JSON.parse(profileString) : null

    //const [user, setUser] = useState<User | null>(null);
    const [user, setUser] = useState<User | null>(savedUser);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

    const [isTeacherUsingStudentView, setIsTeacherUsingStudentView] = useState<boolean>(false);

    /*
    // onload, check if we have a saved user
    useEffect(() => {
        return

        console.log("config", config, process.env)

        console.log("checking profile")
        let profileString = localStorage.getItem(config.LOCALSTORAGE_USER)
        if (profileString) {
            console.log("something...", profileString)
            setUser(JSON.parse(profileString))
            
        }
    }, []);
    */

    // save our user to localstorage when logging in to preserve the session
    /*
    useEffect(() => {

        console.log("rewriting", user)
        if (user == null) {
            localStorage.removeItem(config.LOCALSTORAGE_USER);
            localStorage.removeItem(config.LOCALSTORAGE_REMOTE_USER_ID);
        }
        else {
            localStorage.setItem(config.LOCALSTORAGE_USER, JSON.stringify(user))
            localStorage.setItem(config.LOCALSTORAGE_REMOTE_USER_ID, user.remote_id)
        }
    }, [user]) */

    // auths a google user with our backend
    const authGoogleUser = (googleUser: GoogleUser) => {

        axios
            .post<User>(`${config.BACKEND_URL}/google_user`, {
                ...googleUser
            })
            .then((response) => {
                let user = response.data
                console.log(response.data)

                // save our user in localstorage
                localStorage.setItem(config.LOCALSTORAGE_USER, JSON.stringify(user))
                localStorage.setItem(config.LOCALSTORAGE_REMOTE_USER_ID, user.remote_id)

                setUser(user)
                setIsLoggingIn(false)
            })
            .catch((err) => console.log(err))
    }

    const login = useGoogleLogin({
        // after successful login, we get and save their profile information
        // then we make sure they are a valid Ed user
        onSuccess: (codeResponse) => {

            axios
                .get<GoogleUser>(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${codeResponse.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setIsLoggingIn(true)
                    authGoogleUser(res.data)
                })
                .catch((err) => console.log(err));
            console.log(codeResponse)
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    // logout function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        localStorage.removeItem(config.LOCALSTORAGE_USER);
        localStorage.removeItem(config.LOCALSTORAGE_REMOTE_USER_ID);
        setUser(null);
    };

    if (user) {
        return (


            <div className="app container my-5">

                
<header>
    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
      <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
        <img 
            src="marvi-logo.png" 
            className="me-2" 
            alt="Marvi"
            style={{ width: "60px"}}
        />
        <span className="fs-4">Marvi AI</span>
      </a>

      <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">

        {user.is_teacher && (
            <button 
                className="btn btn-link text-decoration-none text-dark" 
                onClick={() =>setIsTeacherUsingStudentView(!isTeacherUsingStudentView)}
            >
                {isTeacherUsingStudentView ? 
                <span>View Students</span>
                :
                <span>Chat with Marvi</span>
                }
            </button>
        )}
        
        <div className="py-2 text-dark text-decoration-none">
            <img 
                src={user.picture} 
                className="rounded-circle me-2" 
                alt="" 
                style={{ width: "30px"}}
                referrerPolicy="no-referrer"
            /> 
            {user.first_name} {user.last_name}
        </div>
        <button className="btn btn-link mx-3 py-2 text-dark text-decoration-none" onClick={logOut}>
            Logout
        </button>
        {/*
        <button className="mx-3 btn btn-outline-secondary" onClick={logOut}>Log out</button>
        */}
      </nav>
    </div>

  </header>

                <div className="container">

                    {(user.is_teacher && !isTeacherUsingStudentView) ?
                        <HomePageTeacher user={user}></HomePageTeacher>
                        :
                        <HomePageStudent user={user}></HomePageStudent>
                    }

                </div>
            </div>
        )
    }

    return (
        <div className="app my-5">
            <div className='mx-auto' style={{ width : 300}}>
            {isLoggingIn ?
                <h2>Loading</h2>
                :
                <button className="btn btn-outline-primary mx-auto" onClick={() => login()}>Continue with your Google Account</button>
            }
            </div>
        </div>
    );
}
export default App;