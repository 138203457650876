// import dotenv from 'dotenv'
// 
// dotenv.config()

console.log(process.env)

const config = {
    LOCALSTORAGE_USER: process.env.REACT_APP_LOCALSTORAGE_USER!,
    LOCALSTORAGE_REMOTE_USER_ID: process.env.REACT_APP_LOCALSTORAGE_REMOTE_USER_ID!,
    BACKEND_URL: process.env.REACT_APP_BACKEND_URL!,
    IS_DEBUG: process.env.REACT_APP_IS_DEBUG!
}

export default config