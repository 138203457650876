import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// data-bs-theme="dark" 
root.render(
  <GoogleOAuthProvider clientId="968567111843-k11ufas9q6mdf2epatcuquen31dprnin.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
);

/*}
  <GoogleOAuthProvider clientId="968567111843-k11ufas9q6mdf2epatcuquen31dprnin.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
</GoogleOAuthProvider>*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
